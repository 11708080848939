@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

@font-face {
  font-family: 'Gotham Pro Bold';
  src: url('theme/fonts/GothamProBold.ttf');
}

@font-face {
  font-family: 'Gotham Pro Black';
  src: url('theme/fonts/GothamProBlack.ttf');
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('theme/fonts/GothamProRegular.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'helvetica nue', arial, 'Roboto', sans-serif;
  font-weight: 400;
}

* {
  margin: 0;
}

a {
  text-decoration: none;
}
html {
  margin-top: 0 !important;
}

#buorg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.2);
}

#buorg .buorg-pad {
  background-color: #fff4f4;
  border-radius: 5px;
}

#buorg .buorg-buttons {
  display: block;
  text-align: center;
  margin: 15px 0;
}

#buorg .buorg-moremsg {
  display: block;
}

@media print {
  #launcher {
    display: none;
  }
}
